import mutations from "@/store/mutations";
import actions from "@/store/actions";

const endpoint = '/tariffs';

const state = {
    formError: false,
    per_page: 10,
    current_page: 1,
    total: 10,
    items: [],
    fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'bill_no', label: 'Bill No', class: 'text-center' },
        { key: 'bill_type', label: 'TP', class: 'text-center d-none d-sm-table-cell' },
        { key: 'details.user.name', label: 'User', class: 'text-center' },
        { key: 'details.user.billunitnumber', label: 'Bill Unit No', class: 'text-center d-none d-sm-table-cell' },
        { key: 'details.biller.name', label: 'Biller', class: 'text-center d-none d-sm-table-cell' },
        { key: 'total_units', label: 'Units', class: 'text-center' },
        { key: 'total_amount', label: 'AMT', class: 'text-center d-none d-sm-table-cell' },
        { key: 'total_with_mfc', label: 'AMT+MFC', class: 'text-center d-none d-sm-table-cell' },
        { key: 'ed_charges', label: 'ED', class: 'text-center d-none d-sm-table-cell' },
        { key: 'final_amount', label: 'AMT+MFC+ED', class: 'text-center' },
        { key: 'update_dt', label: 'Updated At', class: 'text-center d-none d-sm-table-cell' },
        { key: 'actions', label: 'Actions' }
    ],
    filter: null,
    filterOn: [],
    sortDirection: 'asc'
};

const getters = {
    store: (state) => {
      return state;
    }
};

const Tariff = {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions(endpoint)
}

export default Tariff;