// src/mixins/update.js

import { mapActions } from 'vuex';

export default {
    data() {
      return {
        // refresh variables
        connectionStatus: navigator.onLine ? true : false,
      }
    },
    mounted(){
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
    methods: {
        ...mapActions({ 
            clearAlert: 'alert/clear', 
            errorAlert: 'alert/error', 
            successAlert: 'alert/success', 
        }),
        updateOnlineStatus(e) {
            const { type } = e;
            this.connectionStatus = (type === 'online')? true: false;
            if(type!=='online'){
                this.errorAlert('Internet Disconnected!');
            }else{
                this.successAlert('You are back online!');
                window.location.reload();
            }
        }
    },
  }