import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Admin from '@/views/Admin.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { 
    path: '/admin', 
    name: 'Admin', 
    component: Admin 
  },
  { 
    path: '/biller/user/:id', 
    name: 'UserMeters', 
    component: () => import('../views/UserMeters.vue') 
  },
  { 
    path: '/biller', 
    name: 'Biller', 
    component: () => import('../views/Biller.vue') 
  },
  { 
    path: '/login', 
    name: 'Login', 
    component: () => import('../views/Login.vue') 
  },
  { 
    path: '/register', name: 'Register', 
  component: () => import('../views/Register.vue') },
  { 
    path: '/logout',  redirect: '/login' },
  { 
    path: '/roles', name: 'Roles', 
  component: () => import('../views/admin/Roles.vue') },
  { 
    path: '/permissions', name: 'Permissions', 
  component: () => import('../views/admin/Permissions.vue') },
  { 
    path: '/cities', name: 'Cities', 
  component: () => import('../views/admin/Cities.vue') },
  { 
    path: '/states', name: 'States', 
  component: () => import('../views/admin/States.vue') },
  { path: '/customers',
   name: 'Customers', 
  component: () => import('../views/Customers.vue') },
  { 
    path: '/meters', 
  name: 'Meters', 
  component: () => import('../views/Meters.vue') },
  { 
    path: '/categories', 
  name: 'Categories', 
  component: () => import('../views/Categories.vue') },
  { 
    path: '/tariff-types', 
  name: 'Tariff Types', 
  component: () => import('../views/TariffTypes.vue') },
  { 
    path: '/tariffs', 
  name: 'Tariffs', 
  component: () => import('../views/Tariffs.vue') },
  { 
    path: '/tariffs/:id', 
  name: 'Show Tariff', 
  component: () => import('../views/ShowTariff.vue') },
  { 
    path: '/reports', 
  name: 'Reports', 
  component: () => import('../views/admin/Reports.vue') },
  { 
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
  { 
    path: '/users', 
    name: 'Users', 
    component: () => import('../views/Users.vue') 
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;

// Auth Guard
router.beforeEach((to, from, next) => {
  
  const publicPages = [
    '/login'
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});
