import mutations from "@/store/mutations";
import actions from "@/store/actions";

const endpoint = '/customers';

const state = {
    formError: true,
    per_page: 10,
    current_page: 1,
    total: 10,
    items: [],
    fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'name', label: 'Name', class: 'text-center' },
        { key: 'emp_num', label: 'Emp No', class: 'text-center', 
            formatter: (value, key, item) => {
                return item.is_vacant==1 ? '' : value
            } 
        },
        { key: 'designation', label: 'Desig', class: 'text-center d-none d-sm-table-cell' },
        { key: 'department', label: 'Dept', class: 'text-center d-none d-sm-table-cell' },
        { key: 'billunitnumber', label: 'Bu No', class: 'text-center d-none d-sm-table-cell' },
        { key: 'category.name', label: 'Qtr Type', class: 'text-center d-none d-sm-table-cell' },
        { key: 'quarter', label: 'Qtr No', class: 'text-center d-none d-sm-table-cell' },
        { key: 'city.name', label: 'Code', class: 'text-center d-none d-sm-table-cell' },
        { key: 'state.name', label: 'State', class: 'text-center d-none d-sm-table-cell' },
        { key: 'updated_at', label: 'Updated', class: 'text-center d-none d-sm-table-cell' },
        { key: 'actions', label: 'Actions' }
    ],
    filter: null,
    filterOn: [],
    sortDirection: 'asc',
    stateitems: [ {text: '', value: '' }],
    cities: [{text: '', value: '' }]
};

const getters = {
    store: (state) => {
      return state;
    }
};

const Customer = {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions(endpoint)
}

export default Customer;