<template>
  <div class="wrapper">
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      sticky
    >
      <b-navbar-brand
        :to="rootUrl"
        class="mx-auto"
      >
        SSIPLENGG
      </b-navbar-brand>
      <b-navbar-toggle
        v-if="status.loggedIn"
        target="nav-collapse"
      />
      <b-collapse
        v-if="status.loggedIn"
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav>
          <b-nav-item
            v-for="(val, index) in availableRoutes"
            :key="index"
            :to="val.to"
          >
            {{ val.text }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item href="https://ssiplengg.com">
            New Website
          </b-nav-item>
          <!-- Check mixins/online.js for changes -->
          <b-nav-text
            v-if="connectionStatus"
            class="text-white mr-2"
          >
            <span class="text-green">●</span> Online
          </b-nav-text>
          <b-nav-text
            v-if="!connectionStatus"
            class="text-white mr-2"
          >
            <span class="text-red">●</span> Offline
          </b-nav-text>
          <!-- <b-nav-item>EN</b-nav-item> -->
          <!-- <b-nav-item to="/profile">Profile</b-nav-item> -->
          <b-nav-item to="/logout">
            Logout
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-alert
      v-if="alert.message"
      show="5"
      fade
      dismissible
      class="position-fixed fixed-top m-0 rounded-0"
      :variant="alert.type"
      @dismissed="clearAlert"
    >
      {{ alert.message }}
    </b-alert>

    <router-view />

    <!-- Check mixins/update.js for changes -->
    <b-alert
      :show="updateExists"
      class="position-fixed fixed-bottom m-0 rounded-0 text-center alertz"
      variant="warning"
      dismissible
    >
      An update is available!
      <b-button
        variant="danger"
        size="sm"
        @click="refreshApp"
      >
        Update
      </b-button>
    </b-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import update from "./mixins/update";
import online from "./mixins/online";

export default {
  mixins: [update, online],
  computed: {
    ...mapState({ alert: (state) => state.alert }),
    ...mapState("auth", ["status", "user", "availableRoutes"]),
    rootUrl(){
      if(this.status.loggedIn){
        if( this.user.roles.some( role => role == 'admin' ) ){ return '/admin' }
        else if( this.user.roles.some( role => role == 'biller' ) ) { return '/biller'; }
        else { return '/'; }
      }else{
        return '/';
      }
    }
  },
  mounted() {
    this.updateMenu();
  },
  methods: {
    ...mapActions({ updateMenu: "auth/updateMenu" }),
  },
};
</script>

<style scoped>
.text-white { color: white; }
.text-green { color: lawngreen; }
.text-red { color: crimson; }
.alertz { z-index: 2000; }
</style>