export default {
    setFormError: (state, formError) => (state.formError = formError),
    SET_PWD_FORM_ERROR: (state, formError) => (state.pwdFormError = formError),
    setPerPage: (state, per_page) => (state.per_page = per_page),
    setCurrentPage: (state, current_page) => (state.current_page = current_page),
    setTotal: (state, total) => (state.total = total),
    setItems: (state, items) => (state.items = items),
    addItem:(state, newItem) => state.items.unshift(newItem),
    removeItem:(state,id) => state.items = state.items.filter((item) => item.id !== id),
    updateItem: (state, updatedItem) => {
        const index = state.items.findIndex(item => item.id === updatedItem.id);
        if (index !== -1) state.items.splice(index, 1, updatedItem);
    },
    SET_FILTER: (state, filter) => state.filter = filter,
    SET_STATES: (state, items) => state.stateitems = items,
    SET_CITIES: (state, items) => state.cities = items,
    SET_ROLES: (state, items) => state.roles = items,
    SET_CATEGORIES: (state, categories) => (state.categories = categories),
    setCustomers: (state, customers) => (state.customers = customers)
}