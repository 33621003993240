import { crudService, apiService } from "@/services";
// import router from '@/router';

function actions(endpoint){
    return {
        async fetchItems({ commit, dispatch }, ctx) {
            const response = await crudService.getAll(endpoint, ctx).catch( (error) => dispatch('error/show', error, {root: true}));
            if(response){
                commit('setPerPage', response.data.per_page);
                commit('setCurrentPage', response.data.current_page);
                commit('setTotal', response.data.total);
                commit('setItems', response.data.data);
                return response.data.data;
            }else{
                return {};
            }
        },
        changePage({ commit }, page){
            commit('setCurrentPage', page);
        },
        addItem({ commit, dispatch }, item){
            commit('setFormError', true);
            crudService.create(endpoint, item).then( (response) => {
                commit('setFormError', false);
                commit('addItem', response.data);
                dispatch('alert/success', 'Resource Added.', { root: true });
            }).catch((error) => {
                commit('setFormError', true);
                dispatch('error/show', error, {root: true});
            });
        },
        deleteItem({ commit, dispatch }, id) {
            crudService.delete(endpoint, id).then( (response) => {
                if( response.status == 204 ){
                    dispatch('alert/success', 'Resource Deleted.', { root: true });
                    commit('removeItem', id);
                    commit('setCurrentPage', 1);
                }
            }).catch( (error) => dispatch('error/show', error, {root: true}));
        },
        updateItem({ commit, dispatch }, updatedItem) {
            commit('setFormError', true);
            crudService.update(endpoint, updatedItem).then( (response) => {
                commit('setFormError', false);
                commit('updateItem', response.data);
                dispatch('alert/success', 'Resource Updated.', { root: true });
            }).catch( (error) => dispatch('error/show', error, {root: true}) );   
        },
        setFilter({ commit }, filter) {
            commit('SET_FILTER', filter);
        },
        changePassword({ commit, dispatch }, item){
            commit('SET_PWD_FORM_ERROR', true);
            apiService.changePwd(endpoint, item).then( () => {
                commit('SET_PWD_FORM_ERROR', false);
                dispatch('alert/success', 'Password Updated.', { root: true });
            }).catch( (error) => dispatch('error/show', error, {root: true}) );   
        },
        setCities({ commit, dispatch }){
            apiService.getAll('/options/cities').then( (response) => {
                commit('SET_CITIES', response.data);
            }).catch( (error) => dispatch('error/show', error, {root: true}) )
        },
        setRoles({ commit, dispatch }){
            apiService.getAll('/options/roles').then( (response) => {
                commit('SET_ROLES', response.data);
            }).catch( (error) => dispatch('error/show', error, {root: true}) )
        },
        setStates({ commit, dispatch }){
            apiService.getAll('/options/states').then( (response) => {
                commit('SET_STATES', response.data);
            }).catch( (error) => dispatch('error/show', error, {root: true}) )
        },
        setCategories({ commit, dispatch }){
            apiService.getAll('/options/categories').then( (response) => {
                commit('SET_CATEGORIES', response.data);
            }).catch( (error) => dispatch('error/show', error, {root: true}))
        },
        setCustomers({ commit, dispatch }){
            apiService.getAll('/all/customers').then( (response) => {
                commit('setCustomers', response.data);
            }).catch( (error) => dispatch('error/show', error, {root: true}))
        },
    }
}

export default actions;