import { apiService } from '@/services';
import router from '@/router';

const user = JSON.parse(localStorage.getItem('user'));

const state = user? { status: { loggedIn: true }, user } : { status: {}, user: null };

const Auth = {
    namespaced: true,
    state: {
        ...state,
        rootUrl: '/',
        allroutes: [
            { to: '/about', text: 'About', roles: ['default'] },
            { to: '/biller', text: 'Biller', roles: ['biller'] },
            { to: '/states', text: 'States', roles: ['admin'] },
            { to: '/cities', text: 'Codes', roles: ['admin'] },
            { to: '/customers', text: 'Customers', roles: ['admin'] },
            { to: '/categories', text: 'QTR Type', roles: ['admin'] },
            { to: '/meters', text: 'Meters', roles: ['admin'] },
            { to: '/tariffs', text: 'Tariffs', roles: ['admin', 'biller'] },
            { to: '/reports', text: 'Reports', roles: ['admin', 'default'] },
            // { to: '/roles', text: 'Roles', roles: ['admin'] },
            // { to: '/permissions', text: 'Permissions', roles: ['admin'] },
            { to: '/users', text: 'Users', roles: ['admin'] },
        ],
        availableRoutes: []
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
            state.availableRoutes = [];
        },
        logout(state) {
            state.status = {};
            state.user = null;
            state.availableRoutes = [];
        },
        registerRequest(state) {
            state.status = { registering: true };
        },
        registerSuccess(state) {
            state.status = {};
        },
        registerFailure(state) {
            state.status = {};
            state.availableRoutes = [];
        },
        SET_AVAILABLE_ROUTES(state) {
            if(state.user){
                state.availableRoutes = state.allroutes.filter( route => route.roles.some( r => state.user.roles.some( role => role == r ) ) );
            }  
        }
    },
    getters: {

    },
    actions: {
        updateMenu({commit}){
            commit('SET_AVAILABLE_ROUTES');
        },
        routeByRole( { dispatch }, user ){
            if( user.roles.some( role => role == 'admin') ){
                router.replace('/admin').then( () => dispatch('alert/success', 'Login Success!', { root: true }) );
            }else if( user.roles.some( role => role == 'biller') ){
                router.replace('/biller').then( () => dispatch('alert/success', 'Login Success!', { root: true }) );
            }else{
                if(router.currentRoute.path != '/')
                    router.replace('/').then( () => dispatch('alert/success', 'Login Success!', { root: true }) );
            }
        },
        login({ dispatch, commit }, { email, password }) {
            commit('loginRequest', { email });
            let creds = { email : email, password: password}
            apiService.login( `/login`, creds).then( res => {
                if(res.status == 200){
                    const user = res.data;
                    if (user.access_token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(user));
                    }
                    commit('loginSuccess', user);
                    commit('SET_AVAILABLE_ROUTES');
                    dispatch('routeByRole', user);
                    // router.push("/");
                }
            }).catch( (error) => {
                commit('loginFailure');
                dispatch('error/show', error, {root: true});
            });
        },
        logout({ commit }) {
            commit('logout');
            apiService.logout();
        },
        register({ dispatch, commit }, user) {
            commit('registerRequest', user);
        
            apiService.register(`/register`, user).then(user => {
                commit('registerSuccess', user);
                router.replace('/login');
                setTimeout(() => {
                    // display success message after route change completes
                    dispatch('alert/success', 'Registration successful', { root: true });
                })
            }).catch( (error) => {
                if(error.response){
                    commit('loginFailure', error.response.data);
                    const errors = error.response.data;
                    for (const key in errors) {
                        dispatch('alert/error', errors[key][0], { root: true });
                    }   
                }else{
                    commit('loginFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                }
            });
        }
        
    }
}

export default Auth;