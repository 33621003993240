import mutations from "@/store/mutations";
import actions from "@/store/actions";

const endpoint = '/cities';

const state = {
    formError: true,
    per_page: 10,
    current_page: 1,
    total: 10,
    items: [],
    // fields: [
    //   {
    //     key: 'isActive',
    //     label: 'is Active',
    //     formatter: (value) => {
    //       return value ? 'Yes' : 'No'
    //     },
    //     sortable: true,
    //     sortByFormatted: true,
    //     filterByFormatted: true
    //   },
    // ],
    fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'name', label: 'Name', class: 'text-center' },
        { key: 'slug', label: 'Slug', class: 'text-center d-none d-sm-table-cell' },
        { key: 'consumers_count', label: 'Consumers', class: 'text-center' },
        { key: 'updated_at', label: 'Updated At', class: 'text-center' },
        { key: 'actions', label: 'Actions' }
    ],
    filter: null,
    filterOn: [],
    sortDirection: 'asc'
};

const getters = {
    store: (state) => {
      return state;
    }
};


const City = {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions(endpoint)
}

export default City;