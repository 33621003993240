import mutations from "@/store/mutations";
import actions from "@/store/actions";

const endpoint = '/tarifftypes';

const state = {
    formError: true,
    per_page: 10,
    current_page: 1,
    total: 10,
    items: [],
    fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'name', label: 'Tariff Type', class: 'text-center' },
        { key: 'category.name', label: 'Category', class: 'text-center' },
        { key: 'price', label: 'Price', class: 'text-center' },
        { key: 'updated_at', label: 'Updated At', class: 'text-center d-none d-sm-table-cell' },
        { key: 'actions', label: 'Actions' }
    ],
    filter: null,
    filterOn: [],
    sortDirection: 'asc',
    categories: []
};

const getters = {
    store: (state) => {
      return state;
    }
};

const TariffType = {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions(endpoint)
}

export default TariffType;