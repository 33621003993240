import axios from 'axios';
import { authHeader } from '../helpers';

export const crudService = {
    getAll,
    create,
    getById,
    update,
    delete: _delete,
    logout
};

const apiUrl = process.env.VUE_APP_API_URL;

axios.defaults.headers = authHeader();

function getAll(endpoint, ctx) {
    axios.defaults.headers = authHeader();
    return axios.get(apiUrl + endpoint + `?page=${ctx.currentPage}&filter=${ctx.filter}`);
}

function create(endpoint, item) {
    axios.defaults.headers = authHeader();
    return axios.post(apiUrl + endpoint, item);
}

function getById(endpoint, id) {
    axios.defaults.headers = authHeader();
    return axios.get(apiUrl + endpoint + `/${id}`);
}

function update(endpoint, item) {
    axios.defaults.headers = authHeader();
    return axios.put( apiUrl + endpoint + `/${item.id}`, item);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(endpoint, id) {
    axios.defaults.headers = authHeader();
    return axios.delete( apiUrl + endpoint + `/${id}`);
}

function logout() {
    localStorage.removeItem('user');
}