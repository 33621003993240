<template>
  <b-container class="my-3">
    <b-overlay
      :show="isBusy"
      rounded="sm"
      spinner-type="grow"
      spinner-variant="primary"
    >
      <b-row>
        <div class="col-sm-12">
          <div class="card shadow text-center">
            <h3>Admin Panel</h3>
            <p> {{ new Date() }} </p>
          </div>
        </div>
      </b-row>
      <b-row v-if="!isBusy">
        <div
          v-for="kpi in kpis"
          :key="kpi.key"
          class="my-1 col-sm-4 col-xs-12"
        >
          <div class="card shadow text-center">
            <div class="card-body">
              <h5 class="card-title">
                {{ kpi.label }}
              </h5>
              <p><kbd> {{ kpi.value }} </kbd></p>
              <b-btn
                pill
                variant="primary"
                size="sm"
                :to="kpi.key"
              >
                <i class="fa fa-eye" /> Show
              </b-btn>
            </div>
          </div>
        </div>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import { apiService } from '@/services';
import { mapActions } from 'vuex';

export default {
  name: 'Admin',
  data() {
    return {
      isBusy: false,
      kpis: [
        { id: 0, key: null, label: null, value: null },
      ]
    }
  },
  mounted(){
    this.getKPIs();
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    getKPIs(){
      this.isBusy = true;
      apiService.getAll('/kpis').then( response => {
        this.isBusy = false;
        this.kpis = response.data;
      }).catch(error => {
        this.showError(error);
      })
    }
  }
}
</script>
