import mutations from "@/store/mutations";
import actions from "@/store/actions";

const endpoint = '/users';

const state = {
    formError: false,
    pwdFormError: false,
    per_page: 10,
    current_page: 1,
    total: 10,
    items: [],
    fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'name', label: 'Name', class: 'text-center' },
        { key: 'emp_num', label: 'Emp No', class: 'text-center' },
        { key: 'state.name', label: 'State', class: 'text-center d-none d-sm-table-cell' },
        { key: 'city.name', label: 'City', class: 'text-center d-none d-sm-table-cell' },
        { key: 'roles', label: 'Roles', class: 'text-center d-none d-sm-table-cell', 
            formatter: (value) => { 
                return value.map(item => item.text).toString();
            } 
        },
        { key: 'updated_at', label: 'Updated', class: 'text-center d-none d-sm-table-cell' },
        { key: 'actions', label: 'Actions' }
    ],
    filter: null,
    filterOn: ['name'],
    sortDirection: 'asc',
    stateitems: [ {text: '', value: '' }],
    cities: [{text: '', value: '' }],
    roles: [{text: '', value: '' }]
};

const getters = {
    store: (state) => {
      return state;
    }
};

const User = {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions(endpoint)
}

export default User;