import router from '@/router';

export const error = {
    namespaced: true,
    actions : {
        show({ dispatch }, error) {
            if(error.response){
                const errors = error.response.data;
                for (const key in errors) {
                    dispatch('alert/error', errors[key][0], { root: true });
                }
                if( error.response.status == 401 ){
                    dispatch('auth/logout', null, {root: true});
                    router.replace("/login").catch(error => {
                        console.info(error.message)
                     });  // Push to login here otherwise login page will loop.
                }else if( error.response.status == 403 ){
                    router.go(-1);
                }
            } else{
                dispatch('alert/error', error.message, { root: true });
            }
        }
    },
    mutations: {}
};
