import mutations from "@/store/mutations";
import actions from "@/store/actions";

const endpoint = '/meters';

const state = {
    formError: true,
    per_page: 10,
    current_page: 1,
    total: 10,
    items: [],
    fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'emp_num', label: 'Emp Num', class: 'text-center d-none d-sm-table-cell' },
        { key: 'mnum', label: 'Meter', class: 'text-center' },
        { key: 'prev_read', label: 'Prev Read', class: 'text-center d-none d-sm-table-cell' },
        { key: 'prev_status', label: 'Status', class: 'text-center d-none d-sm-table-cell', 
            formatter: (value) => {
                   return value==1 ? 'Active' : 'Faulty'
            } 
        },
        { key: 'prev_date', label: 'Prev Date', class: 'text-center', 
            formatter: (value) => {
                return value ? (new Date(value)).toDateString() : '';
            }  
        },
        { key: 'username', label: 'User', class: 'text-center d-none d-sm-table-cell'},
        { key: 'updated_at', label: 'Updated', class: 'text-center d-none d-sm-table-cell' },
        { key: 'actions', label: 'Actions' }
    ],
    filter: null,
    filterOn: [],
    sortDirection: 'asc',
    metertypes: [],
    customers: []
};

const getters = {
    store: (state) => {
      return state;
    }
};

const Meter = {
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions(endpoint)
}

export default Meter;