import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import { AlertPlugin } from 'bootstrap-vue';
import { LayoutPlugin } from 'bootstrap-vue';
import { NavbarPlugin } from 'bootstrap-vue';
import { FormPlugin } from 'bootstrap-vue';
import { FormInputPlugin } from 'bootstrap-vue';
import { FormGroupPlugin } from 'bootstrap-vue';
import { InputGroupPlugin } from 'bootstrap-vue';
import { ButtonPlugin } from 'bootstrap-vue';
import { TablePlugin } from 'bootstrap-vue';
import { PaginationPlugin } from 'bootstrap-vue';
import { ModalPlugin } from 'bootstrap-vue';
import { FormSelectPlugin } from 'bootstrap-vue';
import { FormDatepickerPlugin } from 'bootstrap-vue';
import { OverlayPlugin } from 'bootstrap-vue';

/*
import { 
  AlertPlugin,
  LayoutPlugin, 
  NavbarPlugin, 
  FormPlugin, 
  FormInputPlugin, 
  FormGroupPlugin, 
  InputGroupPlugin, 
  ButtonPlugin,
  TablePlugin,
  PaginationPlugin,
  ModalPlugin, 
  FormSelectPlugin,
  FormDatepickerPlugin,
  OverlayPlugin
} from 'bootstrap-vue'
*/

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-bootstrap-typeahead/dist/VueBootstrapTypeahead.css'
import '@/assets/style.css';
import './registerServiceWorker'

Vue.use(Vuelidate)

// Install BootstrapVue
Vue.use(AlertPlugin)
Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(InputGroupPlugin)
Vue.use(ButtonPlugin)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(ModalPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(OverlayPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
