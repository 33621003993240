<template>
  <b-container class="my-1">
    <div class="jumbotron">
      <div class="container text-center">
        <h1>Home</h1>
        <div>
          <p>
            <i class="tagline">A minimal Home Page</i>
          </p>
        </div>
      </div>
    </div>
  </b-container>
</template>

<style scoped>

</style>

<script>
// import { apiService } from '@/services';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Home',
  computed:{
    ...mapState("auth", ["user"])
  },
  mounted() {
    this.routeByRole(this.user)
  },
  methods: {
    ...mapActions({ showError: 'error/show', routeByRole:'auth/routeByRole' }),
  }
}
</script>
