import axios from 'axios';
import { authHeader } from '../helpers';

export const apiService = {
    getAll,
    postAll,
    login,
    register,
    logout,
    changePwd,
    getFile
};

const apiUrl = process.env.VUE_APP_API_URL;

function login(endpoint, user) {
    axios.defaults.headers = authHeader();
    return axios.post( apiUrl + endpoint, user );
}

function register(endpoint, user) {
    axios.defaults.headers = authHeader();
    return axios.post(apiUrl + endpoint, user);
}

function changePwd(endpoint, item) {
    axios.defaults.headers = authHeader();
    return axios.put( apiUrl + endpoint + `/${item.id}/changepwd`, item);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll(endpoint) {
    axios.defaults.headers = authHeader();
    return axios.get(apiUrl + endpoint );
}

function postAll(endpoint, data) {
    axios.defaults.headers = authHeader();
    return axios.post(apiUrl + endpoint, data );
}

function getFile(endpoint, data) {
    axios.defaults.headers = authHeader();
    return axios.post(apiUrl + endpoint, data, { responseType: 'blob' } );
}