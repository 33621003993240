import Vue from 'vue'
import Vuex from 'vuex'
// import createLogger from 'vuex/dist/logger';
import Auth from './auth.store';
import { alert } from './alert.store';
import { error } from './error.store';
import City from './cities.store';
import Role from './roles.store';
import State from './states.store';
import Customer from './customers.store';
import Meter from './meters.store';
import Category from './categories.store';
import TariffType from './tarifftypes.store';
import Tariff from './tariffs.store';
import Permission from './permissions.store';
import User from './users.store';

// createLogger({
//     collapsed: false,
//     filter (mutation) {
//         return mutation.payload.length > 4
//     },
//     transformer (state) {
//         return state.subTree
//     },
//     mutationTransformer (mutation) {
//         return mutation.type
//     },
//     logger: console
// })

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: Auth,
    user: User,
    role: Role,
    permission: Permission,
    city: City,
    stateitem: State,
    customer: Customer,
    meter: Meter,
    metertype: Category,
    tarifftype: TariffType,
    tariff: Tariff,
    alert,
    error
  },
  plugins: [
    // createLogger()
  ]
})
